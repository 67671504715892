import $ from "jquery";
import "../shared/page-view.js";

window.initializeHome = () => {
    $(".cover").pageView({
        current: "DISPLAY_COVER",
        payload: {},
        canvas: null,
        configs: {},
        actions: {
            DISPLAY_COVER: {
                create: (view, config) => {},
            },
        },
    });
};
