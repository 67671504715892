import $ from "jquery";
import {
    format_date,
    render_date,
    render_job_status,
} from "../shared/render-helpers.js";

const CALLBACKS = {
    edition: editionCallback,
    details: detailsCallback,
    history: historyCallback,
};

window.initializeProfile = () => {
    window.ACTION in CALLBACKS && CALLBACKS[window.ACTION]();
};

function editionCallback() {
    $("input[type=checkbox]").click(function (e) {
        const _this = $(this);
        _this.val(_this.prop("checked") === true ? "1" : "0");
    });
    $("#user_profile").submit(function (evt) {
        const data = $(this).serialize();
        evt.preventDefault();
        $.ajax({
            url: "/user/api/v1.0/profile",
            method: "POST",
            data: data,
            success: (data) => {
                console.log(`Profile updated: ${JSON.stringify(data)}`);
                alert("User profile saved");
                window.location.reload();
            },
            error: (err) => {
                console.log("Failed");
                showWarningMessage("An error occurred, please try again");
            },
        });
    });
}

function detailsCallback() {
    $.ajax({
        url: "/user/api/v1.0/upload/count",
        success: (data) => {
            console.log(data);
            $("#userUploadCount").text(data);
        },
        error: (err) => {
            console.log(err);
        },
    });
}

function historyCallback() {
    const ResultsTableConfigs = {
        all_jobs: {
            ajax: "/user/api/v1.0/jobs",
            processing: true,
            serverSide: true,
            select: false,
            responsive: true,
            columnDefs: [
                { targets: "_all", defaultContent: "" },
                {
                    targets: [0],
                    visible: true,
                    title: "ID",
                    type: "num",
                    data: "id",
                },
                {
                    targets: [1],
                    visible: true,
                    title: "Date",
                    type: "str",
                    data: "created",
                    render: render_date(),
                },
                {
                    targets: [2],
                    visible: true,
                    title: "Pipeline",
                    data: "pipeline_name",
                    type: "str",
                },
                {
                    targets: [3],
                    visible: true,
                    title: "Version",
                    type: "str",
                    data: "version_number",
                },
                {
                    targets: [4],
                    title: "Status",
                    visible: true,
                    type: "num",
                    data: "status",
                    render: render_job_status,
                },
            ],
            order: [[1, "desc"]],
        },
    };
    const table = $("#profile #jobs_table").DataTable(
        ResultsTableConfigs.all_jobs,
    );
}
